import React, { createElement, Fragment } from "react";
import classnames from "classnames";

import styles from "./InlineStyleText.module.scss";
import { InlineStyleTextProps } from "./interface";
import styleValueMap from "./styleValueMap";

const inlineStyleTags = {
  [styleValueMap.BOLD]: {
    tag: "strong",
    className: classnames(styles.bold),
  },
  [styleValueMap.CODE]: {
    tag: "code",
    className: classnames(styles.code),
  },
  [styleValueMap.ITALIC]: {
    tag: "em",
    className: classnames(styles.italic),
  },
  [styleValueMap.STRIKETHROUGH]: {
    tag: "strike",
    className: classnames(styles.strike),
  },
  [styleValueMap.UNDERLINE]: {
    tag: "span",
    className: classnames(styles.underline),
  },
};

const InlineStyleText: React.FC<InlineStyleTextProps> = ({ styledText }) => {
  return (
    <>
      {styledText.map(({ text, styleValue }, index) => {
        let result = <Fragment key={`${text}-${index}`}>{text}</Fragment>;

        for (const key of Object.keys(inlineStyleTags)) {
          if (styleValue === 0) break;

          const integerKey = parseInt(key, 10);

          if (styleValue & integerKey) {
            result = createElement(
              inlineStyleTags[integerKey].tag,
              {
                className: inlineStyleTags[integerKey].className,
                key: `${text}-${index}`,
              },
              result
            );

            styleValue -= integerKey;
          }
        }

        return result;
      })}
    </>
  );
};

export default InlineStyleText;
