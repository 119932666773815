import React from "react";

import { ComponentProps } from "@/helpers/componentProps";

import styles from "./ListItem.module.scss";

const ListItem: React.FC<ComponentProps> = ({ children }) => (
  <li className={styles.listItem}>
    <span>{children}</span>
  </li>
);

export default ListItem;
