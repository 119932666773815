import React from "react";
import classnames from "classnames";

import wysiwygStyles from "@/components/WysiwygText/WysiwygText.module.scss";
import { IframeInterface } from "@/components/WysiwygText/Embed/Iframe/Iframe.interface";

import styles from "./Iframe.module.scss";

export const Iframe: React.FC<IframeInterface> = ({ iframe, alignment }) => (
  <div
    className={classnames(
      styles.wrapper,
      wysiwygStyles.block,
      wysiwygStyles[alignment]
    )}
    dangerouslySetInnerHTML={{ __html: `${iframe}` }}
  />
);

export default Iframe;
