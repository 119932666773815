import React from "react";
import classnames from "classnames";

import wysiwygStyles from "@/components/WysiwygText/WysiwygText.module.scss";
import { YouTubeEmbedInterface } from "@/components/WysiwygText/Embed/YouTubeEmbed/YouTubeEmbed.interface";

import styles from "./YouTubeEmbed.module.scss";

export const YouTubeEmbed: React.FC<YouTubeEmbedInterface> = ({
  url,
  alignment,
}) => (
  <div
    className={classnames(
      styles.wrapper,
      wysiwygStyles.block,
      wysiwygStyles[alignment]
    )}
  >
    <iframe
      src={url}
      frameBorder="0"
      allowFullScreen
      className={styles.iframe}
    />
  </div>
);

export default YouTubeEmbed;
