import classnames from "classnames";
import React from "react";

import Quote from "shared/icons/quote.svg";
import Typography from "@/components/Typography";

import wysiwygStyles from "../WysiwygText.module.scss";

import styles from "./BlockQuote.module.scss";
import { BlockQuoteProps } from "./interface";

const BlockQuote: React.FC<BlockQuoteProps> = ({
  alignment = "left",
  author,
  position,
  children,
}) => (
  <blockquote
    className={classnames(
      styles.blockQuote,
      wysiwygStyles[`align-${alignment}`]
    )}
  >
    <Quote className={styles.icon} /> {children}
    {author && (
      <Typography variant="medium" component="p" className={styles.author}>
        {author}
      </Typography>
    )}
    {position && (
      <Typography variant="medium" component="p" className={styles.position}>
        {position}
      </Typography>
    )}
  </blockquote>
);

export default BlockQuote;
