import React from "react";
import classnames from "classnames";

import Typography from "shared/components/Typography";

import wysiwygStyles from "../WysiwygText.module.scss";

import { HeadingProps } from "./interface";
import styles from "./Heading.module.scss";

const Heading: React.FC<HeadingProps> = ({ alignment = "left", children }) => (
  <Typography
    component="h2"
    variant="h4"
    className={classnames(wysiwygStyles[`align-${alignment}`], styles.heading)}
  >
    {children}
  </Typography>
);

export default Heading;
