import React from "react";
import { useRouter } from "next/router";
import classNames from "classnames";

import { ArtistInfoInterface } from "@/components/ArtistInfo/ArtistInfo.interface";
import Avatar from "@/components/Avatar";
import Typography from "@/components/Typography";
import Container from "@/components/Container";
import { applicationRoutes } from "@/helpers/applicationRouting";
import Button from "@/components/Button";
import { placeholder } from "@/helpers/getImgixURL";

import styles from "./ArtistInfo.module.scss";

export const ArtistInfo: React.FC<ArtistInfoInterface> = ({
  image,
  name,
  slug,
  isEmbeded,
}) => {
  const router = useRouter();

  const redirectToArtist = () => {
    router
      .push(`${applicationRoutes.ARTIST}`, applicationRoutes.ARTIST + slug)
      .then(() => window.scrollTo(0, 0));
  };

  return (
    <Container
      className={classNames(styles.header, isEmbeded && styles.embeded)}
    >
      <Avatar
        className={styles.avatar}
        isMusicTile
        size="xlarge"
        src={image.url || placeholder}
        alt={name}
      />

      <div className={styles.wrapper}>
        <Typography variant="hero" component="h1" className={styles.heading}>
          {name}
        </Typography>

        {isEmbeded && (
          <Button
            variant="tertiary"
            onClick={redirectToArtist}
            className={styles.link}
          >
            Przejdź do artysty
          </Button>
        )}
      </div>
    </Container>
  );
};

export default ArtistInfo;
