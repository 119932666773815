import classnames from "classnames";
import React from "react";

import Typography from "shared/components/Typography";

import wysiwygStyles from "../WysiwygText.module.scss";

import styles from "./Paragraph.module.scss";
import { ParagraphProps } from "./interface";

const Paragraph: React.FC<ParagraphProps> = ({
  alignment = "left",
  children,
}) => (
  <Typography
    variant="large"
    component="p"
    className={classnames(
      styles.paragraph,
      wysiwygStyles[`align-${alignment}`]
    )}
  >
    {children}
  </Typography>
);

export default Paragraph;
