import React from "react";
import Link from "next/link";
import classNames from "classnames";
import Image from "next/image";

import { ReleaseInfoInterface } from "@/components/ReleaseInfo/ReleaseInfo.interface";
import Typography from "@/components/Typography";
import { applicationRoutes } from "@/helpers/applicationRouting";
import Rating from "@/components/Rating";
import Container from "@/components/Container";
import { getImgixURL } from "@/helpers/getImgixURL";
import { placeholder } from "@/helpers/getImgixURL";

import styles from "./ReleaseInfo.module.scss";

const AlbumArtwork = (props: { url?: string; alt: string }) => {
  const src = props.url ? getImgixURL(props.url) : placeholder
  
  return (
    <figure className={styles.artwork}>
      <Image
        layout="fill"
        objectFit="cover"
        src={src}
        alt={props.alt}
      />
    </figure>
  );
};

const ReleaseName = (props: { name: string }) => {
  return (
    <Typography variant="hero" component="h1" className={styles.heading}>
      {props.name}
    </Typography>
  );
};

export const ReleaseInfo: React.FC<ReleaseInfoInterface> = ({
  image,
  name,
  artist,
  artistName,
  artistSlug,
  slug,
  ratings,
  isEmbeded,
}) => {
  const composer = {
    name: artistName || artist?.name,
    slug: artistSlug || artist?.slug,
  };

  return (
    <Container
      className={classNames(styles.header, isEmbeded && styles.embeded)}
    >
      {isEmbeded ? (
        <Link
          href={`${applicationRoutes.RELEASE}[slug]`}
          as={applicationRoutes.RELEASE + slug}
        >
          <a>
            <AlbumArtwork url={image.url} alt={name} />
          </a>
        </Link>
      ) : (
        <AlbumArtwork url={image.url} alt={name} />
      )}

      <div className={styles.info}>
        {isEmbeded ? (
          <Link
            href={`${applicationRoutes.RELEASE}[slug]`}
            as={applicationRoutes.RELEASE + slug}
          >
            <a className={styles.link}>
              <ReleaseName name={name} />
            </a>
          </Link>
        ) : (
          <ReleaseName name={name} />
        )}

        {composer.name && (
          <Link
            href={{ pathname: `${applicationRoutes.ARTIST}[slug]` }}
            as={applicationRoutes.ARTIST + composer.slug}
          >
            <a className={styles.name}>
              <Typography
                variant="large"
                component="h2"
                className={classNames(styles.heading, styles.artistName)}
              >
                {composer.name}
              </Typography>
            </a>
          </Link>
        )}

        <Rating
          className={styles.rating}
          slug={slug}
          stars={ratings?.currentUser}
          isEmbeded={isEmbeded}
        />

        {(ratings.average || ratings.redaction) && (
          <div className={styles.rates}>
            {ratings.average && (
              <div className={styles.rate}>
                <Typography
                  variant="h5"
                  component="div"
                  className={styles.score}
                >
                  {(Math.round(ratings.average * 100) / 100).toFixed(1)}
                </Typography>

                <Typography variant="medium" className={styles.label}>
                  Ocena Społeczności
                </Typography>
              </div>
            )}

            {ratings.redaction && (
              <div className={styles.rate}>
                <Typography
                  variant="h5"
                  component="div"
                  className={styles.score}
                >
                  {(Math.round(ratings.redaction * 100) / 100).toFixed(1)}
                </Typography>

                <Typography variant="medium" className={styles.label}>
                  Ocena Redakcji
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default ReleaseInfo;
