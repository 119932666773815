import React from "react";

import { ImageProps } from "./interface";
import styles from "./EmbededImage.module.scss";

const EmbededImage: React.FC<ImageProps> = ({ src, alt, title, caption }) => {
  return (
    <figure className={styles.figure}>
      <img className={styles.image} src={src} alt={alt} title={title} />
      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
    </figure>
  );
};
export default EmbededImage;
