// Mapping inline styles to bits. Without this, we would have to compare
// array of strings to see if styles are the same and if styles are not
// duplicated. During rendering we would have to iterate over array to
// see if style should be applied. This way we have just simple bitwise
// OR/AND, which should be much faster than operations on array.
const styleValueMap = {
  BOLD: 0b00001,
  CODE: 0b00010,
  ITALIC: 0b00100,
  STRIKETHROUGH: 0b01000,
  UNDERLINE: 0b10000,
};

export default styleValueMap;
