import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useStoreState } from "pullstate";

import StarIcon from "shared/icons/star.svg";
import { apiPath } from "shared/helpers/apiPath";
import { UIStore } from "@/store/UIStore";
import client from "@/helpers/apiClient";
import Typography from "@/components/Typography";
import { RatingInterface } from "@/components/Rating/Rating.interface";
import Button from "@/components/Button";

import styles from "./Rating.module.scss";

const devMode = process.env.NODE_ENV === "development";

export const Rating: React.FC<RatingInterface> = ({
  slug,
  stars,
  className,
  isEmbeded,
  withLabel = true,
}) => {
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);
  const [selected, setSelected] = useState<number>(stars ? stars : 0);
  const [hovered, setHover] = useState<number>();

  useEffect(() => {
    stars && setSelected(stars);
  }, [stars]);

  const handleClick = (index) => {
    userLoggedIn ? rateRelease(index) : null;
  };

  const rateRelease = async (index) => {
    try {
      await client.post(apiPath.RATE_RELEASE(slug), {
        value: index,
      });

      setSelected(index);
    } catch (e) {
      // @todo: Error handling?
      if (devMode) {
        console.warn(e);
      }
    }
  };

  const hoverRating = (index) => {
    setHover(index);
  };

  const clearHover = () => {
    setHover(stars ? stars : -1);
  };

  const clearRating = async () => {
    try {
      await client.delete(apiPath.RATE_RELEASE(slug));

      setSelected(0);
    } catch (e) {
      // @todo: Error handling?
      if (devMode) {
        console.warn(e);
      }
    }
  };

  return (
    <>
      <div
        className={classNames(
          styles.wrapper,
          className,
          isEmbeded && styles.embeded,
          !withLabel && styles.embeded
        )}
      >
        {withLabel && (
          <Typography variant="medium" className={styles.heading}>
            Twoja ocena:
          </Typography>
        )}

        {[1, 2, 3, 4, 5].map((_n, index) => (
          <p
            key={index}
            className={styles.starWrapper}
            onMouseEnter={() => hoverRating(index)}
            onMouseLeave={clearHover}
          >
            <StarIcon
              onClick={() => handleClick(index + 1)}
              className={classNames(
                styles.star,
                (hovered >= index || index < selected) && styles.selected,
                "rateAlbum"
              )}
            />
          </p>
        ))}

        {selected !== 0 && withLabel && (
          <Button
            className={styles.remove}
            variant="secondaryStroke"
            onClick={clearRating}
          >
            Usuń ocenę
          </Button>
        )}
      </div>
    </>
  );
};

export default Rating;
