import React from "react";
import Link from "next/link";
import classNames from "classnames";

import styles from "./Anchor.module.scss";
import { AnchorProps } from "./interface";

const Anchor: React.FC<AnchorProps> = ({ href, isExternal, children }) => {
  const portal = process.env.NEXT_PUBLIC_PORTAL;
  const currentHostname = portal === "sport" ? "newonce.sport" : "newonce.net";

  const url = new URL(href);
  const isInternalLink = url.hostname === currentHostname;

  if (isInternalLink) {
    return (
      <Link href={url.pathname+url.search}>
        <a className={classNames(styles.link, portal === "net" && styles.net)}>
          {children}
        </a>
      </Link>
    );
  }

  return (
    <a
      href={href}
      target={isExternal ? "blank" : ""}
      className={classNames(styles.link, portal === "net" && styles.net)}
    >
      {children}
    </a>
  );
};

export default Anchor;
