import { AdSlot } from "react-dfp";
import dynamic from "next/dynamic";

import styles from "../Advertisement.module.scss";

export const ArticleMiddleAd: React.FC = () => {
  const sizes =
    window.innerWidth < 768
      ? [
          [300, 400],
          [300, 250],
        ]
      : [
          [693, 200],
          [1, 1],
        ];

  return (
    <div className={styles.articleMiddle}>
      <AdSlot
        adUnit={`newoncev3_art2(srodtekst)`}
        sizes={sizes}
        slotId="article-mid-slot"
      />
    </div>
  );
};

export default dynamic(() => Promise.resolve(ArticleMiddleAd), { ssr: false });
