import React from "react";

import {
  EntityMap,
  EntityRange,
  EntityType,
  InlineStyleRange,
  StyledTextWithEntity,
} from "./interface";
import styleValueMap from "./InlineStyleText/styleValueMap";
import InlineStyleText from "./InlineStyleText";
import Anchor from "./Anchor";

const mergeLettersToEntityStyledText = (
  letters: { styleValue: number; entityKey: number; text: string }[]
) => {
  let tmpTextWithEntitiesItem = {
      styledText: [{ text: "", styleValue: 0 }],
      styleValue: 0,
      entityKey: -1,
    },
    tmpIndex = 0;
  const textWithEntities: StyledTextWithEntity[] = [];

  // Merge letters into text firstly by entities and secondly by styles
  letters.forEach(({ text, styleValue, entityKey }) => {
    if (
      entityKey === tmpTextWithEntitiesItem.entityKey &&
      styleValue === tmpTextWithEntitiesItem.styleValue
    ) {
      if (text) {
        tmpTextWithEntitiesItem.styledText[tmpIndex].text += text;
      }
    } else if (entityKey === tmpTextWithEntitiesItem.entityKey) {
      if (text) {
        tmpIndex++;
        tmpTextWithEntitiesItem.styleValue = styleValue;
        tmpTextWithEntitiesItem.styledText.push({ text, styleValue });
      }
    } else {
      if (tmpTextWithEntitiesItem.styledText.length) {
        textWithEntities.push(tmpTextWithEntitiesItem);
      }

      tmpIndex = 0;
      tmpTextWithEntitiesItem = {
        styledText: [{ text, styleValue }],
        styleValue,
        entityKey,
      };
    }
  });

  if (tmpTextWithEntitiesItem.styledText.length) {
    textWithEntities.push(tmpTextWithEntitiesItem);
  }

  return textWithEntities;
};

export const attachEntitiesAndInlineStyles = (
  text: string,
  inlineStyleRanges: InlineStyleRange[],
  entityRanges: EntityRange[]
) => {
  const letters = text
    .split("")
    .map((letter) => ({ text: letter, styleValue: 0, entityKey: -1 }));

  // For each letter of `text` we assign it's entityKey
  for (const { offset, length, key } of entityRanges) {
    for (let i = offset; i < offset + length; i++) {
      letters[i] = {
        ...letters[i],
        entityKey: key,
      };
    }
  }

  // For each letter of `text` styleValue is set as a sum of all style values that should be applied
  for (const { offset, length, style } of inlineStyleRanges) {
    for (let i = offset; i < offset + length; i++) {
      const styleValue = styleValueMap[style] || 0;

      letters[i] = {
        ...letters[i],
        styleValue: styleValue | letters[i]?.styleValue,
      };
    }
  }

  return mergeLettersToEntityStyledText(letters);
};

export const getTextWithInlineStyles = (
  textWithEntities: StyledTextWithEntity[],
  entityMap: EntityMap,
  key: string
) =>
  textWithEntities.map(({ styledText, entityKey }, index) => {
    if (entityKey === -1)
      return <InlineStyleText key={key + index} styledText={styledText} />;

    const entity = entityMap[entityKey];

    if (
      entity &&
      (entity.type === EntityType.ANCHOR || entity.type === EntityType.LINK)
    ) {
      return (
        <Anchor
          href={entity.data.url}
          isExternal={entity.data.isExternal || entity.type === EntityType.LINK}
          key={key + index}
        >
          <InlineStyleText styledText={styledText} />
        </Anchor>
      );
    }

    return <></>;
  });
