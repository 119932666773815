import { PodcastTileInterface } from "@/helpers/pageHelpers/Podcast/interface";
import { VideoTileInterface } from "@/helpers/pageHelpers/Common/interface";
import { ReleaseInfoInterface } from "@/components/ReleaseInfo/ReleaseInfo.interface";
import { ArtistInfoInterface } from "@/components/ArtistInfo/ArtistInfo.interface";

export enum BlockType {
  unstyled = "unstyled",
  paragraph = "paragraph",
  headerOne = "header-one",
  headerTwo = "header-two",
  headerThree = "header-three",
  headerFour = "header-four",
  headerFive = "header-five",
  headerSix = "header-six",
  unorderedListItem = "unordered-list-item",
  orderedListItem = "ordered-list-item",
  blockquote = "blockquote",
  codeBlock = "code-block",
  atomic = "atomic",
}

export enum StyleType {
  BOLD = "BOLD",
  CODE = "CODE",
  ITALIC = "ITALIC",
  STRIKETHROUGH = "STRIKETHROUGH",
  UNDERLINE = "UNDERLINE",
}

export interface InlineStyleRange {
  style: StyleType;
  offset: number;
  length: number;
}

export interface EntityRange {
  key: number;
  offset: number;
  length: number;
}

export interface Block {
  key: string;
  type: BlockType;
  text: string;
  depth: number;
  inlineStyleRanges: InlineStyleRange[];
  entityRanges: EntityRange[];
  data?: Record<string, any>;
}

export enum EntityType {
  ANCHOR = "ANCHOR",
  LINK = "LINK",
  BLOCKQUOTE = "BLOCKQUOTE",
  IMAGE = "IMAGE",
  PODCAST_EPISODE = "PODCAST_EPISODE",
  VIDEO = "VIDEO",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  SPOTIFY = "SPOTIFY",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
  EMBED = "EMBED",
  RELEASE = "RELEASE",
  ARTIST = "ARTIST",
}

interface Entity<T = { [key: string]: any }> {
  type: EntityType;
  mutability: "MUTABLE" | "IMMUTABLE" | "SEGMENTED";
  data: T;
}

export interface AlignmentProps {
  alignment?: "left" | "center" | "right";
}

export interface EntityMap {
  [key: string]: Entity;
}

export enum ImageVersionType {
  original = "original",
  cover = "cover",
  facebook = "facebook",
  twitter = "twitter",
}

type ImageVersion = {
  [key in ImageVersionType]: {
    url: string;
  };
};

export interface WysiwygEntityImage {
  versions: ImageVersion[];
  alt: string;
  title: string;
  caption: string;
}

export interface WysiwygEntityPodcastEpisode extends PodcastTileInterface {}

export interface WysiwygEntityVideo extends VideoTileInterface {}

export interface WysiwygEntityRelease extends ReleaseInfoInterface {}

export interface WysiwygEntityArist extends ArtistInfoInterface {}

export interface WysiwygEntityInstagram {
  url: string;
}

interface WysiwygEntity {
  type:
    | EntityType.IMAGE
    | EntityType.PODCAST_EPISODE
    | EntityType.VIDEO
    | EntityType.INSTAGRAM
    | EntityType.RELEASE
    | EntityType.ARTIST;
  id: number;
  object:
    | WysiwygEntityImage
    | WysiwygEntityPodcastEpisode
    | WysiwygEntityVideo
    | WysiwygEntityInstagram
    | WysiwygEntityRelease
    | WysiwygEntityArist;
}

export type WysiwygEntities = WysiwygEntity[];

export interface WysiwygTextProps {
  data: {
    blocks: Block[];
    entityMap: EntityMap;
  };
  entities?: WysiwygEntities;
  isArticle?: boolean;
}

export interface StyledText {
  text: string;
  styleValue: number;
}

export interface StyledTextWithEntity {
  styledText: StyledText[];
  styleValue: number;
  entityKey: number;
}
