import React, { createElement } from "react";
import classnames from "classnames";

import ListItem from "./ListItem";
import styles from "./List.module.scss";
import { ListProps } from "./interface";

const List: React.FC<ListProps> = ({ isOrdered, items, itemsKey }) =>
  createElement(
    isOrdered ? "ol" : "ul",
    { className: classnames(isOrdered ? styles.ol : styles.ul) },
    items.map((item, index) => (
      <ListItem key={`${itemsKey}-${index}`}>{item}</ListItem>
    ))
  );

export default List;
