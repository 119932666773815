import React, { Fragment } from "react";
import InnerHTML from "dangerously-set-html-content";
import InstagramEmbed from "react-instagram-embed";
import { Tweet } from "react-twitter-widgets";
import classNames from "classnames";

import ArticleMiddleAd from "@/components/Advertisement/ArticleMiddle";
import Iframe from "@/components/WysiwygText/Embed/Iframe";
import YouTubeEmbed from "@/components/WysiwygText/Embed/YouTubeEmbed";
import BlockQuote from "@/components/WysiwygText/BlockQuote";

import { BlockType, EntityType, WysiwygTextProps } from "./interface";
import Heading from "./Heading";
import Paragraph from "./Paragraph";
import List from "./List";
import {
  attachEntitiesAndInlineStyles,
  getTextWithInlineStyles,
} from "./helpers";
import Embed from "./Embed";
import styles from "./WysiwygText.module.scss";

export const WysiwygText: React.FC<WysiwygTextProps> = ({
  data,
  entities,
  isArticle,
}) => {
  let currentListAggregator = [],
    currentListType = "";

  return (
    <>
      {data.blocks.map(
        (
          { type, text, inlineStyleRanges, entityRanges, data: blockData, key },
          blockIndex
        ) => {
          if (type === BlockType.atomic) {
            const entityKey = entityRanges[0]?.key;
            if (data.entityMap[entityKey]) {
              const {
                data: { id, url, iframe, code },
                type: entityType,
              } = data.entityMap[entityKey];

              switch (entityType) {
                case EntityType.BLOCKQUOTE:
                  const { text, author, position } =
                    data.entityMap[entityKey].data;
                  return (
                    <BlockQuote
                      key={key}
                      author={author}
                      position={position}
                      alignment={blockData!.alignment}
                    >
                      {text}
                    </BlockQuote>
                  );
                case EntityType.YOUTUBE:
                  return (
                    <YouTubeEmbed
                      key={key}
                      url={url}
                      alignment={blockData!.alignment}
                    />
                  );
                case EntityType.INSTAGRAM:
                  return (
                    <div
                      key={key}
                      className={classNames(
                        styles.block,
                        styles[blockData!.alignment]
                      )}
                    >
                      <InstagramEmbed key={key} url={url} />
                    </div>
                  );
                case EntityType.TWITTER:
                  return (
                    <div
                      key={key}
                      className={classNames(
                        styles.block,
                        styles[blockData!.alignment]
                      )}
                    >
                      <Tweet key={key} tweetId={id} />
                    </div>
                  );
                case EntityType.EMBED:
                  return (
                    <div
                      key={key}
                      className={classNames(
                        isArticle &&
                          (styles.block, styles[blockData!.alignment])
                      )}
                    >
                      <InnerHTML key={key} html={code} />
                    </div>
                  );
                case EntityType.FACEBOOK:
                case EntityType.SPOTIFY:
                  return (
                    <Iframe
                      key={key}
                      iframe={iframe}
                      alignment={blockData!.alignment}
                    />
                  );
                default:
                  return (
                    <Embed
                      key={key}
                      id={id}
                      type={entityType}
                      entities={entities}
                      isArticle={isArticle}
                    />
                  );
              }
            }
          }

          const textWithEntities = attachEntitiesAndInlineStyles(
            text,
            inlineStyleRanges,
            entityRanges
          );

          const textWithInlineStyles = getTextWithInlineStyles(
            textWithEntities,
            data.entityMap,
            key
          );

          if (!textWithEntities.length) return <></>;

          switch (type) {
            case BlockType.headerThree:
            case BlockType.headerTwo: {
              return (
                <Heading key={key} alignment={blockData!.alignment}>
                  {textWithInlineStyles}
                </Heading>
              );
            }
            case BlockType.orderedListItem:
            case BlockType.unorderedListItem: {
              currentListAggregator.push(textWithInlineStyles);
              currentListType = type;
              // if next block doesn't exist or it isn't current list type, then it is end of current list
              if (
                !data.blocks[blockIndex + 1] ||
                data.blocks[blockIndex + 1].type !== currentListType
              ) {
                const list = (
                  <List
                    key={key}
                    items={currentListAggregator}
                    isOrdered={type === BlockType.orderedListItem}
                    itemsKey={key}
                  />
                );
                currentListAggregator = [];
                currentListType = "";
                return list;
              }
              return <></>;
            }
            case BlockType.blockquote: {
              return (
                <BlockQuote key={key} alignment={blockData!.alignment}>
                  {textWithInlineStyles}
                </BlockQuote>
              );
            }
            case BlockType.unstyled:
            case BlockType.paragraph:
            default: {
              return (
                <Fragment key={key}>
                  <Paragraph
                    key={`${key}-paragraph`}
                    alignment={blockData!.alignment}
                  >
                    {textWithInlineStyles}
                  </Paragraph>
                  {isArticle && blockIndex === 4 && <ArticleMiddleAd />}
                </Fragment>
              );
            }
          }
        }
      )}
    </>
  );
};

export default WysiwygText;
