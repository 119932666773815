import React from "react";
import classNames from "classnames";
import Link from "next/link";

import PodcastTile from "@/components/FeedTiles/PodcastTile";
import ReleaseInfo from "@/components/ReleaseInfo";
import ArtistInfo from "@/components/ArtistInfo";
import { routePath } from "@/helpers/routePath";
import { applicationRoutes } from "@/helpers/applicationRouting";

import {
  EntityType,
  ImageVersionType,
  WysiwygEntityArist,
  WysiwygEntityImage,
  WysiwygEntityPodcastEpisode,
  WysiwygEntityRelease,
} from "../interface";

import { EmbedProps } from "./interface";
import EmbededImage from "./EmbededImage";
import styles from "./Embed.module.scss";

const Embed: React.FC<EmbedProps> = ({ isArticle, id, entities, type }) => {
  if (!entities.length) {
    return <></>;
  }

  const data = entities.find(
    (entity) => entity.id === id && entity.type === type
  );

  const entityData = data?.object;

  if (!entityData) {
    return null;
  }

  switch (type) {
    case EntityType.IMAGE: {
      const { versions, alt, title, caption } =
        entityData as WysiwygEntityImage;

      return (
        <EmbededImage
          src={versions[ImageVersionType.original].url}
          alt={alt}
          title={title}
          caption={caption}
        />
      );
    }
    case EntityType.PODCAST_EPISODE: {
      const {
        bookmarked,
        id,
        media,
        picture,
        podcast,
        publishedAt,
        serialNumber,
        slug,
        title,
        images,
        metaDescription,
        sneakPeak,
      } = entityData as WysiwygEntityPodcastEpisode;

      return (
        <PodcastTile
          bookmarked={bookmarked}
          id={id}
          media={media}
          picture={picture}
          images={images}
          podcast={podcast}
          publishedAt={publishedAt}
          serialNumber={serialNumber}
          slug={slug}
          title={title}
          sneakPeak={sneakPeak || metaDescription}
          className={classNames(styles.embed, !isArticle && styles.aligned)}
        />
      );
    }
    case EntityType.RELEASE: {
      const data = entityData as WysiwygEntityRelease;

      return <ReleaseInfo {...data} isEmbeded key={data.id} />;
    }
    case EntityType.ARTIST: {
      const data = entityData as WysiwygEntityArist;

      return (
        <Link
          href={routePath.ARTIST.href}
          as={applicationRoutes.ARTIST + data.slug}
          key={data.id}
        >
          <a className={styles.embededArtist}>
            <ArtistInfo {...data} isEmbeded />
          </a>
        </Link>
      );
    }
    default:
      return <></>;
  }
};

export default Embed;
